import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    maxWidth: '1220px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '130px',
    margin: '0 auto',
    padding: '0 30px',

    '@media screen and (max-width: 900px)': {
      rowGap: '50px',
    },
  },
});

const ContentWrap = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      {children}
    </Box>
  );
};

ContentWrap.defaultProps = {
  children: <></>,
  className: '',
};

ContentWrap.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

export default ContentWrap;
