import { validate } from 'cnpj';

export const NameValidation = ({ name }) => {
  if (!name) {
    return false;
  }
  const validName = name.match(/[^a-zà-ú ]/gi);
  if (!validName && name.length > 2) {
    return true;
  }
  throw new Error('Nome inválido', ['name']);
};

export const LastNameValidation = ({ lastName }) => {
  if (!lastName) {
    return false;
  }
  const validName = lastName.match(/[^a-zà-ú ]/gi);
  if (!validName && lastName.length > 2) {
    return true;
  }
  throw new Error('Sobrenome inválido', ['lastName']);
};

export const PhoneValidation = ({ phone }) => {
  if (phone.length >= 14) {
    return true;
  }
  throw new Error('Telefone inválido', ['phone']);
};

export const EmailValidation = ({ email }) => {
  if (email) {
    const user = email.substring(0, email.indexOf('@'));
    const domain = email.substring(email.indexOf('@') + 1, email.length);
    if (
      (user.length >= 1)
      && (domain.length >= 3)
      && (user.search('@') === -1)
      && (domain.search('@') === -1)
      && (user.search(' ') === -1)
      && (domain.search(' ') === -1)
      && (domain.search('.') !== -1)
      && (domain.indexOf('.') >= 1)
      && (domain.lastIndexOf('.') < domain.length - 1)) {
      return true;
    }
  }
  throw new Error('Email inválido', ['email']);
};

export const CnpjValidation = ({ cnpj }) => {
  if (cnpj && validate(cnpj)) {
    return true;
  }

  throw new Error('CNPJ inválido', ['cnpj']);
};

export const LengthValidation = (data) => {
  const notObligatoryFields = ["partnership"]

  Object.keys(data).forEach((item) => {
    if (!data[item] && !notObligatoryFields.includes(item)) {
      throw new Error('Preencha os campos obrigatórios', [item]);
    }
  });
  return true;
};

export const PasswordValidation = ({ password }) => {
  if (!password) {
    throw new Error('Preencha todos os campos');
  } else if (password.length < 6) {
    throw new Error('A senha deve conter no mínimo 6 caracteres');
  } else {
    return true;
  }
};

export const TermsOfUseValidation = ({ acceptTermsCheck }) => {
  if (!acceptTermsCheck) {
    throw new Error('Você deve aceitar os Termos de uso e Política de privacidade para continuar');
  } else {
    return true;
  }
};

export const ObjectValidation = (data, length) => {
  if (Object.keys(data).length < length) {
    throw new Error('Preencha todos os campos', ['teste']);
  } else {
    return true;
  }
};

export default NameValidation;
