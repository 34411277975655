import React from 'react';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';

const RedirectLink = ({ children, toNewTab, ...rest }) => {
  const newTabProps = toNewTab ? {
    target: '_blank',
    rel: 'noopener noreferrer',
  } : {};

  return (
    <Link {...newTabProps} {...rest}>
      {children}
    </Link>
  );
};

RedirectLink.defaultProps = {
  children: <></>,
  toNewTab: false,
};

RedirectLink.propTypes = {
  children: PropTypes.element,
  toNewTab: PropTypes.bool,
};

export default RedirectLink;
