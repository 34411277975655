import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import clsx from 'clsx';

const TopNavLink = ({
  label, to, backgroundColor, textColor, isNew, color,
}) => {
  const currentRoute = useLocation().pathname;

  const isCurrentPage = currentRoute.includes(to);

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: '20px',
      textDecoration: 'none',
      padding: '6px 20px 10px',
      color: color || theme.palette.midGrey.main,
      transition: '0.2s',

      '& span': {
        fontSize: '12px',
      },

      '&:hover': {
        cursor: 'pointer',
        color: textColor,
        backgroundColor,
        transition: '0.1s',
      },
    },
    currentPage: {
      color: textColor,
    },
    isNew: {
      backgroundColor: '#FFEEDD',
      color: theme.palette.secondary.main,
      padding: '5px 10px',
      borderRadius: '50px',
      fontWeight: 'bold',
      marginLeft: '1%',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <a href={to} className={clsx(classes.root, isCurrentPage ? classes.currentPage : '')}>
        <Typography variant="button">{label}</Typography>
        {isNew ? <Typography variant="caption" className={classes.isNew}>novo</Typography> : ''}
      </a>
    </>
  );
};

TopNavLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default TopNavLink;
