import React from 'react';
import PropTypes from 'prop-types';

export default function SpotifyIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.60938 9.13595C10.4384 8.00295 15.4354 8.78695 17.7644 10.26" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.07031 12.054C10.4433 11.003 14.4263 11.756 16.6293 13.173" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.32422 14.756C11.0772 13.914 13.6832 14.505 15.6532 15.687" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3V3Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

SpotifyIcon.propTypes = {
  color: PropTypes.string,
};

SpotifyIcon.defaultProps = {
  color: '#CDCADE',
};
