import React from 'react';
import PropTypes from 'prop-types';

export default function LinkedinIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3V3Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.11914 11.0996V16.4996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.7188 16.4996V13.3496C11.7188 12.1066 12.7257 11.0996 13.9688 11.0996C15.2118 11.0996 16.2188 12.1066 16.2188 13.3496V16.4996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.11854 7.83789C7.99454 7.83789 7.89354 7.93889 7.89454 8.06289C7.89454 8.18689 7.99554 8.28789 8.11954 8.28789C8.24354 8.28789 8.34454 8.18689 8.34454 8.06289C8.34454 7.93789 8.24354 7.83789 8.11854 7.83789" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

LinkedinIcon.propTypes = {
  color: PropTypes.string,
};

LinkedinIcon.defaultProps = {
  color: '#CDCADE',
};
