import React from 'react';
import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList
} from '@material-ui/core';
import PropTypes from 'prop-types';

const MenuPopper = ({ menuAnchor, setMenuAnchor, children, ...rest }) => {
  const handleClose = (event) => {
    if (menuAnchor && menuAnchor.current && menuAnchor.current.contains(event.target)) {
      return;
    }

    setMenuAnchor(null);
  };

  return (
    <Popper
      open={!!menuAnchor}
      anchorEl={menuAnchor}
      role={undefined}
      transition
      disablePortal
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={!!menuAnchor}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

MenuPopper.defaultProps = {
  menuAnchor: null,
};

MenuPopper.propTypes = {
  menuAnchor: PropTypes.any,
  setMenuAnchor: PropTypes.func.isRequired,
  handleOptions: PropTypes.func.isRequired,
  bill: PropTypes.object.isRequired,
};

export default MenuPopper;
