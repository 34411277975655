import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import axios from 'axios';
import AllowedCities from '../../static/files/AllowedCities.json';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAutocomplete-input': {
      padding: `${theme.overrides.MuiInputBase.input.padding} !important`,
    },
  },
}));

const AutocompleteCityUF = ({
  textfieldlabel, onChange, justWeServe, ...rest
}) => {
  const classes = useStyles();
  const [cities, setCities] = useState([]);

  const renderOptionLabel = (option) => ((option && option.name && option.stateInitial)
    ? `${option.name} - ${option.stateInitial}`
    : '');

  const handleChange = (value) => {
    axios.get(`${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/util/city?name=${value}`)
      .then((response) => {
        if (response && response.data && response.data.cities) {
          setCities(response.data.cities);
        }
      })
      .catch(() => setCities([]));
  };

  return (
    <>
      <Autocomplete
        options={justWeServe ? AllowedCities.cities : cities}
        getOptionLabel={renderOptionLabel}
        onChange={(event, newValue) => onChange(newValue)}
        onInputChange={justWeServe ? null : (event, value) => handleChange(value)}
        className={classes.root}
        renderInput={(params) => {
          params.inputProps.autoComplete = 'none';
          return <TextField label={textfieldlabel} {...params} name="cidade_estado" />;
        }}
        {...rest}
      />
    </>
  );
};

AutocompleteCityUF.defaultProps = {
  textfieldlabel: 'Cidade de Sua Empresa',
  noOptionsText: 'Localização não encontrada',
  onChange: () => {},
  justWeServe: true,
};

AutocompleteCityUF.propTypes = {
  textfieldlabel: PropTypes.string,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  justWeServe: PropTypes.bool,
};


export default AutocompleteCityUF;
