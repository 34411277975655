export const ActivityOptions = [
  { value: 'Advocacia', label: 'Advocacia' },
  { value: 'Afiliados', label: 'Afiliados' },
  { value: 'Agência de Marketing Digital', label: 'Agência de Marketing Digital' },
  { value: 'Arquitetura', label: 'Arquitetura' },
  { value: 'Artes', label: 'Artes' },
  { value: 'Áreas da saúde no geral', label: 'Áreas da saúde no geral' },
  { value: 'Bloggers/Youtubers/etc', label: 'Bloggers/Youtubers/etc' },
  { value: 'Consultoria Empresas', label: 'Consultoria Empresas' },
  { value: 'Consultoria Tecnologia', label: 'Consultoria Tecnologia' },
  { value: 'Corretagem de Imóveis', label: 'Corretagem de Imóveis' },
  { value: 'Corretagem de Seguros', label: 'Corretagem de Seguros' },
  { value: 'Dentista', label: 'Dentista' },
  { value: 'Desenvolvimento Web', label: 'Desenvolvimento Web' },
  { value: 'Design de Interiores', label: 'Design de Interiores' },
  { value: 'Design em Geral', label: 'Design em Geral' },
  { value: 'Design em Gráfico', label: 'Design em Gráfico' },
  { value: 'Design de Interiores', label: 'Design de Interiores' },
  { value: 'Editoração', label: 'Editoração' },
  { value: 'Educação Física', label: 'Educação Física' },
  { value: 'Engenharia', label: 'Engenharia' },
  { value: 'Ensino em Geral', label: 'Ensino em Geral' },
  { value: 'Estúdios Cinematográficos', label: 'Estúdios Cinematográficos' },
  { value: 'Fisioterapia', label: 'Fisioterapia' },
  { value: 'Fotografia', label: 'Fotografia' },
  { value: 'Infoprodutores', label: 'Infoprodutores' },
  { value: 'Intermediação', label: 'Intermediação' },
  { value: 'Jornalismo e Assessoria', label: 'Jornalismo e Assessoria' },
  { value: 'Holdings não financeiras', label: 'Holdings não financeiras' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Marketplace', label: 'Marketplace' },
  { value: 'Médicos', label: 'Médicos' },
  { value: 'Música', label: 'Música' },
  { value: 'Nutrição', label: 'Nutrição' },
  { value: 'Organização e Promoção de Eventos', label: 'Organização e Promoção de Eventos' },
  { value: 'Psicologia', label: 'Psicologia' },
  { value: 'Publicidade e Propaganda', label: 'Publicidade e Propaganda' },
  { value: 'Recursos Humanos', label: 'Recursos Humanos' },
  { value: 'Representação Comercial', label: 'Representação Comercial' },
  { value: 'Salas de Acesso a Internet', label: 'Salas de Acesso a Internet' },
  { value: 'Salão de Beleza', label: 'Salão de Beleza' },
  { value: 'Serviços Administrativos', label: 'Serviços Administrativos' },
  { value: 'Serviços de Lavagem Automotiva', label: 'Serviços de Lavagem Automotiva' },
  { value: 'Serviços de TI', label: 'Serviços de TI' },
  { value: 'Serviços Online (SaaS)', label: 'Serviços Online (SaaS)' },
  { value: 'Tradução', label: 'Tradução' },
  { value: 'Turismo', label: 'Turismo' },
  { value: 'Veterinária', label: 'Veterinária' },
  { value: 'Demais prestação de serviços', label: 'Demais prestação de serviços' },
];

export const FormatOptions = [
  { value: 'EI', label: 'Empresário Individual (EI)' },
  // { value: 'EIRELI', label: 'EIRELI (Empresário Individual de Responsabilidade Limitada)' },
  { value: 'SLU', label: 'Sociedade Limitada Unipessoal (SLU)' },
  { value: 'LTDA', label: 'Sociedade (LTDA)' },
  { value: 'MEI', label: 'Microempreendedor individual (MEI)' },
];

export const SegmentOptions = [
  { value: 'Comércio', label: 'Comércio' },
  { value: 'Indústria', label: 'Indústria' },
  { value: 'Prestação de serviços', label: 'Prestação de serviços' },
  { value: 'Prestação de serviços e comércio', label: 'Prestação de serviços e comércio' },
  // { value: 'Representação comercial', label: 'Representação comercial' },
];

export const monthlyInvoicing = [
  { value: 'até R$20.000/mês', label: 'até R$20.000/mês' },
  { value: 'de R$20.000 até R$30.000/mês', label: 'de R$20.000 até R$30.000/mês' },
  { value: 'de R$30.000 até R$60.000/mês', label: 'de R$30.000 até R$60.000/mês' },
  { value: 'de R$60.000 até R$80.000/mês', label: 'de R$60.000 até R$80.000/mês' },
  { value: 'de R$80.000 até R$100.000/mês', label: 'de R$80.000 até R$100.000/mês' },
  { value: 'de R$100.000 até R$150.000/mês', label: 'de R$100.000 até R$150.000/mês' },
  { value: 'acima de R$150.000/mês', label: 'acima de R$150.000/mês' },
];

export const plans = {
  Online: {
    'até R$20.000/mês': {
      value: 14990,
      defaultValue: 1,
    },
    'de R$20.000 até R$30.000/mês': {
      value: 14990,
      currentValue: 20000,
    },
    'de R$30.000 até R$60.000/mês': {
      value: 19990,
      currentValue: 30000,
    },
    'de R$60.000 até R$80.000/mês': {
      value: 19990,
      currentValue: 60000,
    },
    'de R$80.000 até R$100.000/mês': {
      value: 24990,
      currentValue: 80000,
    },
    'de R$100.000 até R$150.000/mês': {
      value: 29990,
      currentValue: 100000,
    },
    'acima de R$150.000/mês': {
      value: 1,
      currentValue: 150000,
    },
  },
  Private: {
    'até R$20.000/mês': {
      value: 50000,
      defaultValue: 1,
    },
    'de R$20.000 até R$30.000/mês': {
      value: 50000,
      currentValue: 20000,
    },
    'de R$30.000 até R$60.000/mês': {
      value: 50000,
      currentValue: 30000,
    },
    'de R$60.000 até R$80.000/mês': {
      value: 0.05,
      currentValue: 60000,
    },
    'de R$80.000 até R$100.000/mês': {
      value: 0.05,
      currentValue: 80000,
    },
    'de R$100.000 até R$150.000/mês': {
      value: 0.05,
      currentValue: 100000,
    },
    'acima de R$150.000/mês': {
      value: 75001,
      currentValue: 150000,
    },
  },
  Corporate: {
    'até R$20.000/mês': {
      value: 100000,
      defaultValue: 1,
    },
    'de R$20.000 até R$30.000/mês': {
      value: 100000,
      currentValue: 20000,
    },
    'de R$30.000 até R$60.000/mês': {
      value: 100000,
      currentValue: 30000,
    },
    'de R$60.000 até R$80.000/mês': {
      value: 0.1,
      currentValue: 60000,
    },
    'de R$80.000 até R$100.000/mês': {
      value: 0.1,
      currentValue: 80000,
    },
    'de R$100.000 até R$150.000/mês': {
      value: 0.1,
      currentValue: 100000,
    },
    'acima de R$150.000/mês': {
      value: 0.1,
      currentValue: 150000,
    },
  },
  Comércio: {
    'até R$20.000/mês': {
      value: 23000,
      defaultValue: 1,
    },
    'de R$20.000 até R$30.000/mês': {
      value: 26000,
      currentValue: 20000,
    },
    'de R$30.000 até R$60.000/mês': {
      value: 30000,
      currentValue: 30000,
    },
    'de R$60.000 até R$80.000/mês': {
      value: 0.1,
      currentValue: 35000,
    },
    'de R$80.000 até R$100.000/mês': {
      value: 0.1,
      currentValue: 37500,
    },
    'de R$100.000 até R$150.000/mês': {
      value: 0.1,
      currentValue: 45000,
    },
    'acima de R$150.000/mês': {
      value: 1,
      currentValue: 150000,
    },
  },
};

export const Interest = [
  { value: 'Desejo abrir uma empresa', label: 'Desejo abrir uma empresa' },
  { value: 'Já tenho empresa aberta', label: 'Já tenho empresa aberta' },
  { value: 'Sou contador e quero mais informações', label: 'Sou contador e quero mais informações' },
  { value: 'Sou estudante e quero aprender mais', label: 'Sou estudante e quero aprender mais' },
  { value: 'Descobrir o melhor formato para minha empresa', label: 'Descobrir o melhor formato para minha empresa' },
  { value: 'Quero mudar de contador', label: 'Quero mudar de contador' },
];
export default ActivityOptions;
