import React from 'react';

const IconRightArrow = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H1C0.585787 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585787 6.75 1 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6Z" fill="#573DA8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46967 0.46967C9.17678 0.762563 9.17678 1.23744 9.46967 1.53033L14.4697 6.53033C14.7626 6.82322 15.2374 6.82322 15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.5303 0.46967C10.2374 0.176777 9.76256 0.176777 9.46967 0.46967Z" fill="#573DA8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5303 5.46967C15.2374 5.17678 14.7626 5.17678 14.4697 5.46967L9.46967 10.4697C9.17678 10.7626 9.17678 11.2374 9.46967 11.5303C9.76256 11.8232 10.2374 11.8232 10.5303 11.5303L15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967Z" fill="#573DA8"/>
  </svg>
);

export default IconRightArrow;
