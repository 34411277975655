import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import ContentWrap from '../atoms/ContentWrap';
import StyledButton from '../atoms/StyledButton';
import FooterMenuDesktop from '../atoms/FooterMenuDesktop';
import FooterMenuMobile from '../atoms/FooterMenuMobile';
import RedirectLink from '../atoms/RedirectLink';
import InstagramIcon from '../atoms/icons/InstagramIcon';
import FacebookIcon from '../atoms/icons/FacebookIcon';
import YoutubeIcon from '../atoms/icons/YoutubeIcon';
import SpotifyIcon from '../atoms/icons/SpotifyIcon';
import LinkedinIcon from '../atoms/icons/LinkedinIcon';
import ConubeLogo from '../../static/images/logo-conube-branco.png';
import { useDrawerContext } from '../../contexts/DrawerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '60px 0 25px 0',
    borderRadius: '50px 50px 0px 0px',
    backgroundColor: theme.palette.primary.dark,

    '@media screen and (max-width: 1140px)': {
      borderRadius: '16px 16px 0px 0px',
      padding: '40px 0 35px',
    },

    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
    },

    '& .MuiLink-root, & .MuiLink-root .MuiTypography-root': {
      ...theme.typography.body2,
      color: theme.palette.secondary.contrastText,
    },

    '& .highlighted': {
      fontWeight: '700',
      letterSpacing: '0.065em',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '320px auto 260px',
    gap: '50px',

    '& .conube-data': {
      display: 'grid',
      gap: '20px',

      '& .documents': {
        display: 'flex',
        gap: '30px',

        '& .MuiTypography-root': {
          fontWeight: '700',
          letterSpacing: '0.065em',
        },
      },
    },

    '& .button': {
      textAlign: 'right',

      '& .MuiTypography-body1': {
        margin: '30px 0 15px',
      },

      '& .MuiTypography-body2': {
        fontWeight: 400,
      },
    },

    '& .menu-mobile': {
      '@media screen and (min-width: 1140px)': {
        display: 'none',
      },
    },

    '@media screen and (max-width: 1140px)': {
      gridTemplateColumns: 'auto',

      '& .menu-desktop': {
        display: 'none',
      },
    },
  },
  bottom: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    marginTop: '35px',
    paddingTop: '25px !important',
    borderTop: '0.6px solid #8645FF',

    '& .icons': {
      display: 'flex',
      justifySelf: 'right',
      gap: '15px',

      '& img': {
        width: '30px',
      },
    },

    '@media screen and (max-width: 1140px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '30px',
    },
  },
}));

const Footer = ({ taNaModa }) => {
  const classes = useStyles();
  const { setOpenDrawer } = useDrawerContext();

  return (
    <Box className={classes.root}>
      <ContentWrap>
        <Box>
          <Box className={classes.content}>
            <Box className="conube-data">
              <img src={ConubeLogo} alt="Logo da Conube" width="137px" height="27px" />

              <Typography variant="subtitle1">Conube Contabilidade Online LTDA</Typography>

              <Box className="documents">
                <Typography variant="subtitle2">
                  CNPJ:
                  <br />
                  18.761.396/0001-55
                </Typography>

                <Typography variant="subtitle2">
                  CRC:
                  <br />
                  2SP032732
                </Typography>
              </Box>

              <Box className="documents">
                <Typography variant="subtitle2">
                  Conube Tecnologia e Serviços Ltda. | CNPJ: 21.196.219/0001-88
                </Typography>
              </Box>

              <Typography variant="body1">
                Endereço: Estr. São Francisco, 2008 - Jardim Wanda
                Taboão da Serra/SP - CEP 06765-465
                Salas 809 e 810
              </Typography>
            </Box>

            <Box className="menu-desktop">
              <FooterMenuDesktop />
            </Box>

            <Box className="menu-mobile">
              <FooterMenuMobile />
            </Box>

            <Box className="button">
              {taNaModa ? (
                <StyledButton
                  text="Fale com um consultor"
                  variant="outlined2"
                  color="white"
                  width="100%"
                  onClick={() => setOpenDrawer(true)}
                />
              ) : (
                <StyledButton
                  text="Saiba quanto custa"
                  variant="outlined2"
                  color="white"
                  width="100%"
                  component="a"
                  href="/quanto-custa/"
                />
              )}

              <Typography variant="body1" className="highlighted">Portal de Ajuda</Typography>

              <RedirectLink href="https://suporte.conube.com.br/" toNewTab>
                <Typography variant="body2">Perguntas Frequentes</Typography>
              </RedirectLink>
            </Box>
          </Box>

          <Box className={classes.bottom}>
            <Typography variant="body2">©2021 Conube - Todos direitos reservados</Typography>

            <Box className="icons">
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/conubecontabilidade">
                <InstagramIcon />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/conube/">
                <FacebookIcon />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/ContabilidadeOnline">
                <YoutubeIcon />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://br.linkedin.com/company/conube">
                <LinkedinIcon />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/show/2Vy7E4zSOVBZvYPKMqzi0N">
                <SpotifyIcon />
              </a>
            </Box>
          </Box>
        </Box>
      </ContentWrap>
    </Box>
  );
};

export default Footer;
