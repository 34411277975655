import React from 'react';

// node_modules
import Button from '@material-ui/core/Button';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const palette = [
  {
    color: 'primary',
    first: 'rgb(87, 61, 168)',
    second: 'rgb(185, 168, 220);',
    third: 'rgb(237, 232, 246)',
    dark: 'rgb(74, 52, 141)',
  },
  {
    color: 'primary2',
    first: 'rgb(255, 255, 255)',
    second: 'rgb(255, 255, 255);',
    third: 'rgb(87, 61, 168)',
    dark: 'rgb(87, 61, 168)',
  },
  {
    color: 'secondary',
    first: 'rgba(68, 160, 219)',
    second: 'rgba(68, 160, 219, 0.4)',
    third: 'rgba(68, 160, 219, 0.1)',
    dark: 'rgb(62, 140, 189)',
  },
  {
    color: 'error',
    first: 'rgba(214, 32, 70)',
    second: 'rgba(214, 32, 70, 0.4)',
    third: 'rgba(214, 32, 70, 0.1)',
    dark: 'rgba(161, 25, 54)',
  },
  {
    color: 'warning',
    first: 'rgba(241, 119, 0)',
    second: 'rgb(245, 195, 153)',
    third: 'rgb(249, 221, 191)',
    dark: 'rgba(189, 94, 0)',
  },
  {
    color: 'info',
    first: 'rgba(124, 46, 126)',
    second: 'rgba(124, 46, 126, 0.4)',
    third: 'rgba(124, 46, 126, 0.1)',
    dark: 'rgb(106, 39, 107)',
  },
  {
    color: 'success',
    first: 'rgba(72, 173, 158)',
    second: 'rgb(172, 221, 216)',
    third: 'rgb(210, 235, 231)',
    dark: 'rgba(52, 126, 115)',
  },
  {
    color: 'white',
    first: 'rgba(255, 255, 255)',
    second: 'rgb(255, 255, 255)',
    third: 'rgba(0, 0, 0, 0)',
    dark: 'rgba(255, 255, 255)',
  },
  {
    color: 'midGrey',
    first: 'rgba(102,100,122)',
    second: 'rgb(102,100,122)',
    third: 'rgba(0, 0, 0, 0)',
    dark: 'rgba(102,100,122)',
  },
  {
    color: 'tnm',
    first: '#8645FF',
    second: '#FAC010',
  },
];

// eslint-disable-next-line consistent-return
const addStyle = (variant, buttonColor) => {
  if (variant && buttonColor !== 'primary' && buttonColor !== 'secondary') {
    switch (variant) {
      case 'contained':
        return {
          color: 'white',
          backgroundColor: `${buttonColor.first}`,
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: `${buttonColor.dark}`,
          },

          '&:disabled': {
            backgroundColor: '#cccbd2',
          },
        };

      case 'contained2':
        return {
          color: `${buttonColor.first}`,
          backgroundColor: `${buttonColor.third}`,
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: `${buttonColor.third}`,
          },
        };

      case 'outlined2':
        return {
          color: `${buttonColor.first}`,
          border: `1px solid ${buttonColor.second}`,

          '&:hover': {
            border: `1px solid ${buttonColor.second}`,
            backgroundColor: `${buttonColor.third}`,
          },
        };

      case 'light':
        return {
          color: `${buttonColor.first}`,
          backgroundColor: '#fff',

          '&:hover': {
            backgroundColor: 'rgb(244, 244, 244)',
          },
        };

      case 'tnm':
        return {
          color: '#FFF',
          backgroundColor: buttonColor.first,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: buttonColor.second,
            color: '#1A1738',
          },
          '&:disabled': {
            backgroundColor: '#cccbd2',
          },

        };

      case 'purpleFriday': {
        return {
          color: '#FFF',
          backgroundColor: '#8645FF',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#8645FF',
            color: '#FFF',
          },
          '&:disabled': {
            backgroundColor: '#cccbd2',
          },
        };
      }

      default:
        return '';
    }
  }
};

export default function StyledButton(props) {
  const {
    text, height, width, loading, borderRadius, ...rest
  } = props;

  const buttonColor = palette.find((param) => param.color === props.color);

  const useStyles = makeStyles({
    globalStyle: {
      position: 'relative',
      borderRadius,
      height,
      width,
      // FIXME
      // eslint-disable-next-line react/destructuring-assignment
      ...addStyle(props.variant, buttonColor),

      '& .MuiCircularProgress-root': {
        width: '30px !important',
        height: '30px !important',
        marginLeft: '10px',
        position: 'absolute',
        right: '15px',
      },
    },
  });
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button className={classes.globalStyle} {...rest}>
      {text}
      {loading ? <CircularProgress variant="indeterminate" color="primary" /> : ''}
    </Button>
  );
}

StyledButton.defaultProps = {
  text: 'Default Button',
  loading: false,
  color: 'primary',
  height: '55px',
  variant: null,
  width: '200px',
  borderRadius: 50,
  disabled: false,
};

StyledButton.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
};
